@import 'styles/variables';

.header-guest {
  &__login-button {
    color: #716f80;
    background: transparent;
    border: 1.5px solid #716f80;
    transition: all 200ms cubic-bezier(0.39, 0.575, 0.565, 1);
    padding-left: 23px !important;
    padding-right: 23px !important;
    display: flex;
    align-items: center;

    &:hover {
      color: #342f41;
      border-color: #342f41;
      opacity: 1;
      transform: translate(0, 0);
    }
  }

  margin-right: -5px;

  @media #{$webflow-lg-breakpoint} {
    margin-right: -15px;
  }

  &__login-button {
    background-color: #f4f2ff;
    border: none;
    width: 38px;
    height: 38px;
    display: none;
    justify-content: center;
    align-items: center;
    padding: 0 !important;

    @media #{$breakpoint-md-up} {
      display: flex;
      min-width: 92px;
      background-color: transparent;
      border: 1px solid #1c1a2180;
      padding: 0 22px;
    }

    :global .login-text {
      display: none;

      @media #{$breakpoint-md-up} {
        display: block;
      }
    }
  }

  :global .header__request-button {
    padding-top: 7px !important;
    padding-bottom: 7px !important;

    .btn__inner {
      line-height: 23px;
    }
  }

  @media #{$breakpoint-lg-up} {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 7px;

    :global .header__request-button {
      display: block;
    }
  }
}
